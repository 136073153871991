<template>
  <div class="pass-form">
    <form @submit.prevent="resetPassword">
      <h5>
        Welcome to MDME!
      </h5>
      <h5>
        Your password is temporary/has expired. Before continuing, you must
        reset your password to a new one of your choice.
      </h5>
      <h5>
        If you do not do this immediately, you may be locked out of your
        account, and an administrator will need to resend you your new account
        verification.
      </h5>
      <div class="form-group">
        <input
          placeholder="New password:"
          type="password"
          required
          autocomplete="new-password"
          class="form-control form-control-lg"
          v-model="form.pass1"
        />
      </div>
      <div class="form-group">
        <input
          placeholder="Re-enter new password:"
          required
          type="password"
          class="form-control form-control-lg"
          v-model="form.pass2"
        />
      </div>
      <button
        :disabled="loading"
        type="submit"
        class="btn-blue"
        @click.stop.prevent="resetPassword"
      >
        <template v-if="!loading">
          Reset Password
        </template>
        <template v-else>
          <b-spinner type="grow"></b-spinner>
          Loading...
        </template>
      </button>
    </form>
    <a
      class="pass-link"
      @click.stop.prevent="$router.push('/login').catch(() => {})"
      >Got here by mistake? Click to login.</a
    >
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      confirmed: false,
      form: {
        pass1: "",
        pass2: "",
      },
      loading: false,
    };
  },
  mounted() {
    // can't do this without the reset user.
    if (!this.getResetUser) {
      this.$router.push({ name: "Login" }).catch(() => {});
      return;
    }
  },
  beforeDestroy() {
    if (this.getResetUser) {
      this.$store.commit("setResetUser", null);
    }
  },
  computed: {
    ...mapGetters(["getResetUser"]),
  },
  methods: {
    async resetPassword() {
      this.loading = true;
      try {
        const { pass1, pass2 } = this.form;
        const { user } = this.getResetUser;
        if (pass1 != pass2) {
          return this.$store.dispatch("createErrors", "Passwords must match.");
        }
        const self = this;
        await Auth.completeNewPassword(user, pass1)
          .then(() => {
            // ignore user - only passes when password is updated
            self.loading = false;
            self.$store.dispatch(
              "createAlerts",
              "Your password has been changed!"
            );
            self.$router.push("/").catch(() => {});
          })
          .catch((err) => {
            if (err && err.code && err.code == "InvalidPasswordException") {
              // return the message to the user!
              return self.$store.dispatch("createErrors", err.message);
            } else {
              return self.$store.dispatch(
                "createErrors",
                "There was an issue resetting your password, please try again."
              );
            }
          });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
